export const STATES_DATA: any = {
    'NY': {
        logo: '/state-logos/logoNewYorkDivorce.png',
        plainLogo: '/state-plain-logos/ny.png',
        primaryColor: '#14324C',
        secondaryColor: '#197352',
        siteName: 'New York Divorce Online'
    },
    'MD': {
        logo: '/state-logos/logoMarylandDivorce.png',
        plainLogo: '/state-plain-logos/maryland.png',
        primaryColor: '#000000',
        secondaryColor: '#981E32',
        siteName: 'Maryland Divorce Online'
    },
    'TX': {
        logo: '/state-logos/logoTexasDivorce.png',
        plainLogo: '/state-plain-logos/texas.png',
        primaryColor: '#14324C',
        secondaryColor: '#981E32',
        siteName: 'Texas Divorce Online'
    },
    'NJ': {
        logo: '/state-logos/logoNewJerseyDivorce.png',
        plainLogo: '/state-plain-logos/texas.png',  //TODO no NJ in figma - request if needed
        primaryColor: '',
        secondaryColor: '',
        siteName: 'New Jersey Divorce Online'
    },
    'AL': {
        logo: '/state-logos/logoAlabamaDivorce.png',
        plainLogo: '/state-plain-logos/alabama.png',
        primaryColor: '#981E32',
        secondaryColor: '#14324C',
        siteName: 'Alabama Divorce Online'
    },
    'FL': {
        logo: '/state-logos/logoFloridaDivorce.png',
        plainLogo: '/state-plain-logos/florida.png',
        primaryColor: '#981E32',
        secondaryColor: '#FAAB1A',
        siteName: 'Fast Florida Divorce'
    },
    'GA': {
        logo: '/state-logos/logoGeorgiaDivorce.png',
        plainLogo: '/state-plain-logos/georgia.png',
        primaryColor: '#14324C',
        secondaryColor: '#EAAB00',
        siteName: 'Georgia Divorce Online'
    },
    'WA': {
        logo: '/state-logos/logoWashingtonDivorce.png',
        plainLogo: '/state-logos/logoWashingtonDivorce.png', //TODO PLAIN LOGO FOR WA MISSING
        primaryColor: 'rgba(36, 192, 219, 1)',
        secondaryColor: 'rgba(20, 49, 76, 1)',
        siteName: 'Washington Divorce Online'
    },
}